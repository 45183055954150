.chat-container {
    display: flex;
    flex-direction: column;
    height: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .message {
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .message.sent {
    text-align: right;
  }
  
  .message.received {
    text-align: left;
  }
  
  .message-input {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
  }
  
  .message-input input {
    flex: 1;
    padding: 5px;
  }
  
  .message-input button {
    padding: 5px;
  }